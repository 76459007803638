<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Solicitudes Diarias</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Tipo Busqueda</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="tipo_consulta"
                    :class="
                      $v.tipo_consulta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option :value="1">Solicitud</option>
                    <option :value="2">Detalle Solicitud</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>Nombre</label>
                <input
                  type="number"
                  id="id"
                  placeholder="ID"
                  label="Id"
                  v-model="filtros.id"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Campo</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="sitio"
                  placeholder="Campos"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.sitios"
                  :filterable="true"
                  @input="getSelectSitios()"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Nominación</label>

                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="nominacion"
                  placeholder="Nominaciones"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.nominaciones"
                  :filterable="true"
                  @input="getSelectNominaciones()"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Fecha</label>
                <input
                  type="date"
                  id="fecha"
                  v-model="filtros.fecha"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.tipo_consulta.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="
                    $store.getters.can('hidrocarburos.solicitudes.pivotTable')
                  "
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "SolicitudPivotTable",
  components: {
    PivotTableComponent,
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      sitio: {},
      nominacion: {},
      filtros: {
        id: null,
        sitio_id: null,
        nominacion_id: null,
        fecha: null,
      },
      tipo_consulta: null,
      listasForms: {
        sitios: [],
        nominaciones: [],
      },
    };
  },
  validations: {
    tipo_consulta: {
      required,
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Hidrocarburos/Solicitudes");
    },
    async getNominaciones() {
      this.cargando = true;
      axios.get("/api/hidrocarburos/nominacion/list").then((response) => {
        this.listasForms.nominaciones = response.data;
        this.cargando = false;
      });
    },
    async getSitios() {
      this.cargando = true;
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
        this.cargando = false;
      });
    },

    getSelectSitios() {
      this.filtros.sitio = {};
      this.filtros.sitio_id = null;
      if (this.sitio) {
        this.filtros.sitio = this.sitio;
        this.filtros.sitio_id = this.sitio.id;
      }
    },
    getSelectNominaciones() {
      this.filtros.nominacion = {};
      this.filtros.nominacion_id = null;
      if (this.nominacion) {
        this.filtros.nominacion = this.nominacion;
        this.filtros.nominacion_id = this.nominacion.id;
      }
    },

    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/solicitudes/pivotTable",
        data: { tipo_consulta: this.tipo_consulta, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getNominaciones();
    await this.getSitios();
    this.cargando = false;
  },
};
</script>
